// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/cspilhere/Projects/malwee-app/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cadastro-js": () => import("/Users/cspilhere/Projects/malwee-app/src/pages/cadastro.js" /* webpackChunkName: "component---src-pages-cadastro-js" */),
  "component---src-pages-compartilhar-js": () => import("/Users/cspilhere/Projects/malwee-app/src/pages/compartilhar.js" /* webpackChunkName: "component---src-pages-compartilhar-js" */),
  "component---src-pages-index-js": () => import("/Users/cspilhere/Projects/malwee-app/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instituicao-js": () => import("/Users/cspilhere/Projects/malwee-app/src/pages/instituicao.js" /* webpackChunkName: "component---src-pages-instituicao-js" */),
  "component---src-pages-instituicoes-js": () => import("/Users/cspilhere/Projects/malwee-app/src/pages/instituicoes.js" /* webpackChunkName: "component---src-pages-instituicoes-js" */),
  "component---src-pages-politica-js": () => import("/Users/cspilhere/Projects/malwee-app/src/pages/politica.js" /* webpackChunkName: "component---src-pages-politica-js" */),
  "component---src-pages-regulamento-js": () => import("/Users/cspilhere/Projects/malwee-app/src/pages/regulamento.js" /* webpackChunkName: "component---src-pages-regulamento-js" */)
}

