module.exports = [{
      plugin: require('/Users/cspilhere/Projects/malwee-app/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"malwee-app","short_name":"malwee","start_url":"/","background_color":"#FFF","theme_color":"#FFF","display":"minimal-ui"},
    },{
      plugin: require('/Users/cspilhere/Projects/malwee-app/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NNCQ68J","includeInDevelopment":false},
    },{
      plugin: require('/Users/cspilhere/Projects/malwee-app/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
